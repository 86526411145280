import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { confirmDelete } from "../../utils/commonFunctions";
import { DeleteProgramAction, getPrograms, reorderProgram } from "../../redux/actions/programAction";

export default function Index() {
    const history = useHistory();
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getPrograms(setLoading));
    }, [dispatch]);

    let { allPrograms } = useSelector((state) => state.programs);

    const [loading, setLoading] = useState(false);

    const [search, setSearch] = useState("");
    const getFilteredData = (data, search) => {
        if (search.length) {
            data = data.filter((item) => item?.name?.toLowerCase()?.includes(search) || item?.description?.toLowerCase()?.includes(search));
        }
        return data;
    };

    allPrograms = getFilteredData(allPrograms, search);

    const onDelete = (programId, position) => {
        confirmDelete(
            () => {
                dispatch(DeleteProgramAction(programId));
            },
            "Do you want to delete this Program?",
            position
        );
    };

    const [reorderd, setReorderd] = useState([]);

    useEffect(() => {
        setReorderd(allPrograms);
    }, [allPrograms]);

    const handleChnage = (e) => {
        setReorderd(e.value);
    };

    const onSave = () => {
        dispatch(
            reorderProgram(reorderd, setLoading, () => {
                history.push("/programs/");
            })
        );
    };
    allPrograms = getFilteredData(allPrograms, search);

    return {
        allPrograms,
        loading,
        history,
        search,
        setSearch,
        onDelete,
        handleChnage,
        reorderd,
        onSave,
    };
}
