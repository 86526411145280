export const LevelOptions = [
    {
        name: "Beginner",
        value: "Beginner",
    },
    {
        name: "Experienced",
        value: "Experienced",
    },
];

export const MeditationType = [
    {
        name: "Breathwork Meditation",
        value: "Breathwork Meditation",
    },
    {
        name: "Visualization Meditation",
        value: "Visualization Meditation",
    },
    {
        name: "Guided Meditation",
        value: "Guided Meditation",
    },
    {
        name: "Reiki Meditation",
        value: "Reiki Meditation",
    },
];

export const GenderOptions = [
    { name: "Male", value: "MALE" },
    { name: "Female", value: "FEMALE" },
    { name: "Other", value: "OTHER" },
    { name: "Nothing", value: "NOTHING" },
];

export const YesNoOptions = [
    { name: "Yes", value: true },
    { name: "No", value: false }
];

export const CurrencyOptions = [
    { name: "Indian Rupee (INR)", value: "INR" },
    { name: "US Dollar (USD)", value: "USD" }
];

export const PeriodOptions = [
    { name: "Days", value: "daily" },
    { name: "Week", value: "weekly" },
    { name: "Month", value: "monthly" },
    { name: "Year", value: "yearly" }
];
