import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { deleteCustomer, getCustomers } from "../../redux/actions/customerAction";
import { confirmPopup } from "primereact/confirmpopup";
import moment from "moment";
import { Dropdown } from "primereact/dropdown";

export default function Index() {
    const history = useHistory();
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getCustomers(setLoading));
    }, [dispatch]);

    let { allCustomers } = useSelector((state) => state.customer);

    const [loading, setLoading] = useState(false);

    const [search, setSearch] = useState("");
    const [subscriptionfilters, setSubscriptionFilters] = useState(null);
    const filter = [
        { name: "All", value: "All" },
        { name: "Subscribed", value: "Subscribed" },
        { name: "Expired", value: "Expired" },
        { name: "UnSubscribed", value: "UnSubscribed" },
    ];
    const getFilteredData = (data, search) => {
        if (search.length) {
            data = data.filter(
                (item) =>
                    item?.firstName?.includes(search) ||
                    item?.lastName?.includes(search) ||
                    item?.mobile?.includes(search) ||
                    item?.email?.includes(search)
            );
        }
        if (subscriptionfilters) {
            if (subscriptionfilters === "Subscribed") {
                data = data.filter((item) => moment(item.subscriptionExpireOn).isAfter(moment()));
            } else if (subscriptionfilters === "Expired") {
                data = data.filter((item) => moment(item.subscriptionExpireOn).isBefore(moment()));
            } else if (subscriptionfilters === "UnSubscribed") {
                data = data.filter((item) => !item.subscriptionExpireOn);
            }
        }
        return data;
    };

    allCustomers = getFilteredData(allCustomers, search);
    allCustomers = allCustomers.map((item) => ({
        ...item,
        time: new Date(item.time),
        subscriptionExpireOn: item.subscriptionExpireOn ? new Date(item.subscriptionExpireOn) : null,
    }));
    let inActiveCustomers = allCustomers.filter((item) => item.isDeleted);
    inActiveCustomers = getFilteredData(inActiveCustomers, search);

    const onDelete = (e, id) => {
        confirmPopup({
            target: e.currentTarget,
            message: "Are you sure you want to delete this user? This action will permanently delete the user account. This action cannot be undone.",
            icon: "pi pi-exclamation-triangle",
            accept: () => dispatch(deleteCustomer(id)),
            reject: () => {},
        });
    };

    const headerContent = () => {
        return (
            <>
                <Dropdown
                    value={subscriptionfilters}
                    placeholder="Subscription Filter"
                    onChange={(e) => setSubscriptionFilters(e.value)}
                    name="filter"
                    optionLabel="name"
                    options={filter}
                />
            </>
        );
    };

    return {
        allCustomers,
        inActiveCustomers,
        loading,
        history,
        search,
        setSearch,
        onDelete,
        headerContent,
    };
}
