import React from "react";
import { OrderList } from "primereact/orderlist";
import CustomCard from "../../shared/Card/CustomCard";
import Index from "./index";
import PrimaryButton from "../../shared/Button/PrimaryButton";
import { getImageURL } from "../../utils/imageUrl";
import { Avatar } from "primereact/avatar";
export default function ReorderMenu({ name }) {
    const { handleChnage, reorderd, onSave, loading } = Index();
    const itemTemplate = (item) => {
        return (
            <div className="flex flex-wrap p-2 align-items-center gap-3">
                <Avatar image={getImageURL(item.image)} size="xlarge" shape="circle" />
                <div className="flex-1 flex flex-column gap-2 xl:mr-8">
                    <span className="font-bold">{item.name}</span>
                </div>
            </div>
        );
    };
    return (
        <CustomCard title={name} backable>
            <div className="p-4 categories">
                <OrderList
                    style={{ minHeight: "50vh" }}
                    value={reorderd}
                    onChange={handleChnage}
                    itemTemplate={itemTemplate}
                    header="Menu"
                    dragdrop
                ></OrderList>
                <br />

                <PrimaryButton loading={loading} label="Save" onClick={onSave} />
            </div>
        </CustomCard>
    );
}
