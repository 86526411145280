import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
// import { getCustomer, addCustomer, editCustomer } from "../../../../redux/actions/customerActions";
import { allValidations } from "../../../utils/formValidations";
import { showFormErrors } from "../../../utils/commonFunctions";
import { getCategories } from "../../../redux/actions/locationMenuAction";
import { addTracks, editTracks, getTrack } from "../../../redux/actions/tracksAction";
import { updateUploadProgress } from "../../../redux/actions/uploadProgess";
import { getImageURL } from "../../../utils/imageUrl";
import { getAuthors } from "../../../redux/actions/authorAction";

export default function TrackContainer(setOpenTasks) {
    const dispatch = useDispatch();
    const history = useHistory();
    const { id } = useParams();
    useEffect(() => {
        dispatch(getCategories());
        dispatch(getAuthors(null));
        dispatch(updateUploadProgress(0));
    }, [dispatch]);

    let { allLocationsMenu } = useSelector((state) => state.locationMenu);
    let { allAuthors } = useSelector((state) => state.author);
    useEffect(() => {
        if (id) {
            dispatch(
                getTrack(id, (data) => {
                    setData({
                        image: data.image ? [data.image] : [],
                        banner: data.banner ? [data.banner] : [],
                        category: data?.category,
                        author: data?.author,
                        title: data?.title,
                        type: data?.type,
                        level: data?.level,
                        duration: data?.duration,
                        description: data?.description,
                        whatYouExpect: data?.whatYouExpect,
                        howToMeditate: data?.howToMeditate,
                        bonusTip: data?.bonusTip,
                        music: data.music ? [data.music] : [],
                        isRecommended: data?.isRecommended,
                        isSubscriptionRequired: data?.isSubscriptionRequired,
                    });
                })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, dispatch]);

    const [formType, setFormType] = useState("ADD");
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({
        category: [],
        title: "",
        image: [],
        banner: [],
        type: "",
        level: "",
        author: "",
        duration: 10,
        description: "",
        whatYouExpect: [],
        howToMeditate: [],
        bonusTip: "",
        music: [],
        isRecommended: false,
        isSubscriptionRequired: false,
    });

    const [duration, setDuration] = useState(0);

    const getDuration = async (file) => {
        if (!file) {
            console.error("No valid file provided.");
            return 0;
        }
        try {
            const audio = new Audio();
            audio.src = getImageURL(file[0]);
            audio.preload = "metadata";

            await new Promise((resolve, reject) => {
                audio.onloadedmetadata = function () {
                    resolve(audio.duration);
                    setDuration(Math.round(audio.duration / 60));
                };
                audio.onerror = function (error) {
                    reject(error);
                };
            });
            URL.revokeObjectURL(audio.src);
        } catch (error) {
            console.error("Error getting audio duration:", error);
            return 0;
        }
    };

    useEffect(() => {
        if (data?.music?.length > 0) {
            const getDurationFn = async () => {
                await getDuration(data?.music);
            };
            getDurationFn();
        }
    }, [data?.music]);

    const handleChange = ({ name, value }) => {
        if (formType !== "VIEW") {
            const formErrors = allValidations(name, value, data);
            setData((prev) => ({ ...prev, [name]: value, formErrors }));
        }
    };

    const onSubmit = () => {
        if (showFormErrors(data, setData)) {
            if (formType === "ADD") {
                dispatch(addTracks(data, duration, setLoading, history, setOpenTasks, dispatch));
            } else if (formType === "EDIT") {
                dispatch(editTracks(id, duration, data, setLoading, history, dispatch));
            }
        }
    };

    return { data, handleChange, loading, onSubmit, setFormType, allLocationsMenu, allAuthors };
}
