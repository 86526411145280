import React, { useEffect } from "react";
import { CustomDropDown, CustomForm, CustomInput, CustomInputSwitch, CustomTextArea } from "../../../shared/Input/AllInputs";
import PrimaryButton, { PrimaryButtonOutlined } from "../../../shared/Button/PrimaryButton";
import CustomImageInput from "../../../shared/Input/CustomImageInput";
import ProgramContainer from "./ProgramContainer";
import { Dialog } from "primereact/dialog";
import { OrderList } from "primereact/orderlist";

export default function ProgramForm({ type }) {
    const {
        data,
        handleChange,
        loading,
        onSubmit,
        setFormType,
        allTracks,
        trackData,
        handleTrackChange,
        isTrackModelOpen,

        onOpenTrackModel,
        onCloseTrackModel,
        itemTemplate,
        onAddTrackSubmit,
    } = ProgramContainer();
    useEffect(() => {
        setFormType(type);
    }, [type, setFormType]);

    return (
        <>
            <CustomForm style={type === "VIEW" ? { pointerEvents: "none" } : {}}>
                <CustomImageInput
                    name="coverImage"
                    label="Upload Cover Image"
                    col={6}
                    onFilesChange={handleChange}
                    data={data}
                    editable={type !== "VIEW" ? true : false}
                />
                <CustomInput label="Name" name="name" required data={data} onChange={handleChange} />

                <CustomTextArea label="Description" name="description" required data={data} onChange={handleChange} />
                <div className="flex justify-content-end w-full ">
                    <div className="flex ">
                        <PrimaryButton label="Add Track" onClick={onOpenTrackModel} />
                    </div>
                </div>
                <OrderList
                    className="w-full"
                    value={data.tracks}
                    onChange={(e) => {
                        handleChange({ name: "tracks", value: e.value });
                    }}
                    itemTemplate={itemTemplate}
                    header="Program Tracks"
                    dragdrop
                ></OrderList>
                {type !== "VIEW" && <PrimaryButton label="Save Programs" loading={loading} onClick={onSubmit} col={6} />}
            </CustomForm>

            <Dialog
                header={"Add Track"}
                visible={isTrackModelOpen}
                style={{ width: "48vw" }}
                draggable={false}
                resizable={false}
                onHide={onCloseTrackModel}
            >
                <CustomForm>
                    <CustomInput label="Title" name="title" data={trackData} onChange={handleTrackChange} col={12} required />
                    <CustomDropDown
                        optionLabel="title"
                        label="Track"
                        name="track"
                        options={allTracks}
                        data={trackData}
                        onChange={handleTrackChange}
                        col={12}
                        required
                        filter
                    />
                    <CustomInputSwitch label="Break" name="isBreak" data={trackData} onChange={handleTrackChange} col={12} />
                    <div className="flex justify-content-end w-full">
                        <PrimaryButton label="Add Track" loading={loading} onClick={onAddTrackSubmit} />
                        <PrimaryButtonOutlined label="Cancel" onClick={onCloseTrackModel} />
                    </div>
                </CustomForm>
            </Dialog>
        </>
    );
}
