import api from "../../services/api";
import { types } from "../types/types";
import Constants from "../../services/constant";
import { showToast } from "./toastAction";
import { showLoaderAction, hideLoaderAction } from "./loaderAction";
import { uploadImages } from "../../utils/uploadFiles";

const getAuthors = (setLoading) => async (dispatch) => {
    if (setLoading) {
        setLoading(true);
    }
    const res = await api("get", Constants.END_POINT.AUTHORS);
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.CHANGE_AUTHORS,
                payload: res.data,
            });
        }
    }
    if (setLoading) {
        setLoading(false);
    }
};
const addAuthor = (data, setLoading, history) => async (dispatch) => {
    setLoading(true);

    if (data.image.length) {
        let urls = await uploadImages(data.image);
        data.image = urls[0];
    }

    const res = await api("post", Constants.END_POINT.AUTHORS, data);
    if (res.success) {
        history.goBack();
        dispatch(showToast({ severity: "success", summary: res.message }));
    }
    setLoading(false);
};
const getAuthor = (id, returnData) => async (dispatch) => {
    dispatch(showLoaderAction());
    const res = await api("get", Constants.END_POINT.AUTHORS + id);
    if (res.success) {
        dispatch(hideLoaderAction());
        if (res.data) {
            if (returnData) {
                returnData(res.data);
            }
        }
    }
    dispatch(hideLoaderAction());
};
const editAuthor = (id, data, setLoading, history) => async (dispatch) => {
    setLoading(true);

    if (data.image.length) {
        let urls = await uploadImages(data.image);
        data.image = urls[0];
    }

    const res = await api("put", Constants.END_POINT.AUTHORS + id, { ...data });
    if (res.success) {
        history.goBack();
        dispatch(showToast({ severity: "success", summary: res.message }));
    }
    setLoading(false);
};
const deleteAuthorAction = (id) => async (dispatch) => {
    const res = await api("delete", Constants.END_POINT.AUTHORS + id);
    if (res.success) {
        dispatch(getAuthors(null));
        dispatch(showToast({ severity: "success", summary: res.message }));
    } else {
        dispatch(showToast({ severity: "error", summary: res.message }));
    }
};

const reorderPlans = (plans, setLoading, next) => async (dispatch) => {
    let payload = plans.map((item) => item._id);
    setLoading(true);
    const res = await api("post", Constants.END_POINT.PLANS + "reorder", payload);
    setLoading(false);
    if (res.success) {
        if (next) {
            next();
        }
    }
};

export { getAuthors, addAuthor, getAuthor, editAuthor, deleteAuthorAction, reorderPlans };
