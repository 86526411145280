import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { allValidations } from "../../utils/formValidations";
import { showFormErrors } from "../../utils/commonFunctions";
import { editCustomer, getCustomer } from "../../redux/actions/customerAction";

export default function CustomerContainer() {
    const dispatch = useDispatch();
    const history = useHistory();

    const { id } = useParams();

    const [data, setData] = useState({});
    const ignoreFields = ["image"];

    useEffect(() => {
        if (id) {
            dispatch(
                getCustomer(id, (data) => {
                    setData({
                        image: data.image ? [data.image] : [],
                        firstName: data?.firstName,
                        lastName: data?.lastName,
                        email: data?.email,
                        countryCode: data.countryCode,
                        mobile: data?.mobile,
                        city: data?.city,
                        dateOfBirth: data?.dateOfBirth ? new Date(data?.dateOfBirth) : "",
                        gender: data?.gender,
                        subscriptionExpireOn: data?.subscriptionExpireOn ? new Date(data?.subscriptionExpireOn) : "",
                    });
                })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, dispatch]);

    const [formType, setFormType] = useState("EDIT");
    const [loading, setLoading] = useState(false);

    const handleChange = ({ name, value }) => {
        if (formType !== "VIEW") {
            const formErrors = allValidations(name, value, data);
            setData((prev) => ({ ...prev, [name]: value, formErrors }));
        }
    };

    const onSubmit = () => {
        if (showFormErrors(data, setData, ignoreFields)) {
            if (formType === "EDIT") {
                dispatch(editCustomer(id, data, setLoading, history));
            }
        }
    };

    return { data, handleChange, loading, onSubmit, setFormType };
}
