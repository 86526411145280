import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { confirmDelete } from "../../utils/commonFunctions";
import { deletePlanAction, getPlans, reorderPlans } from "../../redux/actions/plansAction";

export default function Index() {
    const history = useHistory();
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getPlans(setLoading));
    }, [dispatch]);

    let { allPlans } = useSelector((state) => state.plans);

    const [loading, setLoading] = useState(false);

    const [search, setSearch] = useState("");
    const getFilteredData = (data, search) => {
        if (search.length) {
            data = data.filter((item) => item?.name?.toLowerCase()?.includes(search) || item?.description?.toLowerCase()?.includes(search));
        }
        return data;
    };

    allPlans = getFilteredData(allPlans, search);

    const onDelete = (planId, position) => {
        confirmDelete(
            () => {
                dispatch(deletePlanAction(planId));
            },
            "Do you want to delete this Plan?",
            position
        );
    };

    const [reorderd, setReorderd] = useState([]);
    useEffect(() => {
        setReorderd(allPlans);
    }, [allPlans]);
    const handleChnage = (e) => {
        setReorderd(e.value);
    };

    const onSave = () => {
        dispatch(
            reorderPlans(reorderd, setLoading, () => {
                history.push("/plans/");
            })
        );
    };
    allPlans = getFilteredData(allPlans, search);
    return {
        allPlans,
        loading,
        history,
        search,
        setSearch,
        onDelete,
        reorderd,
        onSave,
        handleChnage,
    };
}
