import api from "../../services/api";
import { types } from "../types/types";
import Constants from "../../services/constant";
import { showToast } from "./toastAction";
import { showLoaderAction, hideLoaderAction } from "./loaderAction";

const getCustomers = (setLoading) => async (dispatch) => {
    if (setLoading) {
        setLoading(true);
    }
    const res = await api("get", Constants.END_POINT.CUSTOMER);
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.CHANGE_CUSTOMERS,
                payload: res.data,
            });
        }
    }
    if (setLoading) {
        setLoading(false);
    }
};

const CustomerEnableDisableAction = (id) => async (dispatch) => {
    const res = await api("get", Constants.END_POINT.ENABLE_DISABLE_CUSTOMER + id);
    if (res.success) {
        dispatch(getCustomers());
        dispatch(showToast({ severity: "success", summary: res.message }));
    } else {
        dispatch(showToast({ severity: "error", summary: res.message }));
    }
};

const getCustomer = (id, returnData) => async (dispatch) => {
    dispatch(showLoaderAction());
    const res = await api("get", Constants.END_POINT.CUSTOMER + id);
    if (res.success) {
        dispatch(hideLoaderAction());
        if (res.data) {
            if (returnData) {
                returnData(res.data);
            }
        }
    }
    dispatch(hideLoaderAction());
};
const editCustomer = (id, data, setLoading, history) => async (dispatch) => {
    setLoading(true);

    const payload = {
        ...data,
    };

    const res = await api("put", Constants.END_POINT.CUSTOMER + id, payload);
    if (res.success) {
        getCustomers(null);
        history.goBack();
        dispatch(showToast({ severity: "success", summary: res.message }));
    }
    setLoading(false);
};

const deleteCustomer = (id) => async (dispatch) => {
    dispatch(showLoaderAction());
    const res = await api("delete", Constants.END_POINT.CUSTOMER + id);
    if (res.success) {
        dispatch(getCustomers());
        dispatch(showToast({ severity: "success", summary: res.message }));
    } else {
        dispatch(showToast({ severity: "error", summary: res.message }));
    }
    dispatch(hideLoaderAction());
};

const getFeedbacks = (setLoading) => async (dispatch) => {
    if (setLoading) {
        setLoading(true);
    }
    const res = await api("get", Constants.END_POINT.FEEDBACKS);
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.CHANGE_FEEDBACKS,
                payload: res.data,
            });
        }
    }
    if (setLoading) {
        setLoading(false);
    }
};

const deleteFeedback = (id) => async (dispatch) => {
    dispatch(showLoaderAction());
    const res = await api("delete", Constants.END_POINT.FEEDBACKS + id);
    if (res.success) {
        dispatch(getFeedbacks());
        dispatch(showToast({ severity: "success", summary: res.message }));
    } else {
        dispatch(showToast({ severity: "error", summary: res.message }));
    }
    dispatch(hideLoaderAction());
};

export { getCustomers, getCustomer, CustomerEnableDisableAction, editCustomer, deleteCustomer, getFeedbacks, deleteFeedback };
