import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
// import { getCustomer, addCustomer, editCustomer } from "../../../../redux/actions/customerActions";
import { allValidations } from "../../../utils/formValidations";
import { showFormErrors } from "../../../utils/commonFunctions";
import { getTracks } from "../../../redux/actions/tracksAction";
import { getImageURL } from "../../../utils/imageUrl";
import { addPrograms, editProgram, getProgram, addTrackInProgram, removeTrackFromProgram } from "../../../redux/actions/programAction";
import Break from "../../../assets/images/break.jpg";

import { Avatar } from "primereact/avatar";
import PrimaryButton from "../../../shared/Button/PrimaryButton";
import { confirmDialog } from "primereact/confirmdialog";

export default function ProgramContainer() {
    const dispatch = useDispatch();
    const history = useHistory();
    const { id } = useParams();

    useEffect(() => {
        dispatch(getTracks());
    }, [dispatch]);

    let { allTracks } = useSelector((state) => state.tracks);

    useEffect(() => {
        getPrograms(id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, dispatch, allTracks]);

    const getPrograms = (id) => {
        if (id) {
            dispatch(
                getProgram(id, (data) => {
                    setData({
                        coverImage: data.coverImage ? [data.coverImage] : [],
                        name: data?.name,
                        description: data?.description,
                        tracks: data?.tracks,
                    });
                })
            );
        }
    };

    const [formType, setFormType] = useState("ADD");
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({
        coverImage: ["https://images.pexels.com/photos/6634306/pexels-photo-6634306.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"],
        name: "",
        description: "",
        tracks: [],
    });

    const [isTrackModelOpen, setIsTrackModelOpen] = useState(false);
    const [trackData, setTrackData] = useState({
        title: "",
        track: {},
        isBreak: false,
    });

    const onOpenTrackModel = () => {
        setIsTrackModelOpen(true);
        setTrackData({
            title: `Day ${data.tracks.length + 1}`,
            track: {},
            isBreak: false,
        });
    };
    const onCloseTrackModel = () => {
        setIsTrackModelOpen(false);
        setTrackData({
            title: "",
            track: {},
            isBreak: false,
        });
    };

    const handleTrackChange = ({ name, value }) => {
        setTrackData((prev) => ({ ...prev, [name]: value }));
    };

    const handleChange = ({ name, value }) => {
        if (formType !== "VIEW") {
            const formErrors = allValidations(name, value, data);
            setData((prev) => ({ ...prev, [name]: value, formErrors }));
        }
    };

    const onAddTrackSubmit = () => {
        if (id) {
            dispatch(
                addTrackInProgram(id, trackData, setLoading, () => {
                    onCloseTrackModel();
                    getPrograms(id);
                })
            );
        } else {
            setData((prev) => ({ ...prev, tracks: [...prev.tracks, trackData] }));
            onCloseTrackModel();
        }
    };

    const onSubmit = () => {
        if (showFormErrors(data, setData)) {
            if (formType === "ADD") {
                dispatch(addPrograms(data, setLoading, history));
            } else if (formType === "EDIT") {
                dispatch(editProgram(id, data, setLoading, history));
            }
        }
    };
    const itemTemplate = (item) => {
        return (
            <div className="flex flex-wrap p-2 align-items-center gap-3">
                <>
                    {item?.isBreak ? (
                        <>
                            <Avatar image={Break} size="xlarge" shape="circle" />
                            <div className="flex-1 flex flex-column gap-2 xl:mr-8">
                                <span className="font-bold">Break</span>
                                <div className="flex align-items-center gap-2">
                                    <i className="pi pi-calendar text-sm"></i>
                                    <span>{item.title}</span>
                                </div>
                            </div>
                            <span className="font-bold text-900">
                                <PrimaryButton label="Remove" severity="warning" onClick={() => onRemoveTrack(item)} />
                            </span>
                        </>
                    ) : (
                        <>
                            <Avatar image={getImageURL(item?.track?.image)} size="xlarge" shape="circle" />
                            <div className="flex-1 flex flex-column gap-2 xl:mr-8">
                                <span className="font-bold">{item?.track?.title}</span>
                                <div className="flex align-items-center gap-2">
                                    <i className="pi pi-calendar text-sm"></i>
                                    <span>{item.title}</span> <i className="pi pi-clock text-sm"></i> {item?.track?.duration}Min
                                </div>
                            </div>
                            <span className="font-bold text-900">
                                <PrimaryButton label="Remove" severity="warning" onClick={() => onRemoveTrack(item)} />
                            </span>
                        </>
                    )}
                </>
            </div>
        );
    };

    const onRemoveTrack = (track) => {
        console.log(track);
        if (track._id) {
            confirmDialog({
                message: "Are you sure you want to Remove?",
                header: "Confirmation",
                icon: "pi pi-exclamation-triangle",
                accept: () => {
                    dispatch(
                        removeTrackFromProgram(track._id, () => {
                            getPrograms(id);
                        })
                    );
                },
                reject: () => {},
            });
        } else {
            let _newTracksArr = data.tracks;
            let index = _newTracksArr.findIndex((item) => item.title === track.title);
            _newTracksArr.splice(index, 1);
            setData((prev) => ({ ...prev, tracks: _newTracksArr }));
        }
    };

    return {
        data,
        handleChange,
        loading,
        onSubmit,
        setFormType,
        itemTemplate,

        allTracks,

        trackData,
        handleTrackChange,
        isTrackModelOpen,

        onOpenTrackModel,
        onCloseTrackModel,
        onAddTrackSubmit,
    };
}
