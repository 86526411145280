import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { confirmDelete } from "../../utils/commonFunctions";
import { deleteFeedback, getFeedbacks } from "../../redux/actions/customerAction";

export default function Index() {
    const history = useHistory();
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getFeedbacks(setLoading));
    }, [dispatch]);

    let { allFeedbacks } = useSelector((state) => state.customer);

    const [loading, setLoading] = useState(false);

    const [search, setSearch] = useState("");
    const getFilteredData = (data, search) => {
        if (search.length) {
            data = data.filter((item) => item?.firstName?.toLowerCase()?.includes(search) || item?.email?.toLowerCase()?.includes(search));
        }
        return data;
    };

    allFeedbacks = getFilteredData(allFeedbacks, search);

    const onDelete = (feedId, position) => {
        confirmDelete(
            () => {
                dispatch(deleteFeedback(feedId));
            },
            "Do you want to delete this Feedback?",
            position
        );
    };

    return {
        allFeedbacks,
        loading,
        history,
        search,
        setSearch,
        onDelete,
    };
}
