import { types } from "../types/types";
const intitalState = {
    allLocationsMenu: [],
};

const locationMenuReducer = (state = intitalState, action) => {
    switch (action.type) {
        case types.CHANGE_LOCATIONS_MENU:
            return {
                ...state,
                allLocationsMenu: action.payload,
            };
        default:
            return { ...state };
    }
};

export default locationMenuReducer;
