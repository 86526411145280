import React from "react";
import TableCard from "../../shared/TableCard/TableCard";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

export default function FeedbacksTable({ heading, allFeedbacks, loading, search, setSearch, onDelete }) {
    const actions = (d) => {
        return (
            <div className="flex justify-content-around">
                <i className="pi pi-trash cursor-pointer" onClick={() => onDelete(d._id)}></i>
            </div>
        );
    };

    return (
        <TableCard title={heading} onSearch={setSearch} searchKeyword={search} buttonTitle="Add Author" linkTo={`/authors/add`}>
            <DataTable paginator value={allFeedbacks} showGridlines rows={10} loading={loading} emptyMessage="No author found.">
                <Column body={(e) => e.firstName + " " + e?.lastName} header="Name" style={{ width: "150px" }}></Column>
                <Column field="email" header="Email" style={{ width: "150px" }}></Column>
                <Column field="reason" header="Feedback" style={{ width: "350px" }}></Column>
                <Column body={actions} style={{ width: "80px" }} header="Action"></Column>
            </DataTable>
        </TableCard>
    );
}
