import api from "../../services/api";
import { types } from "../types/types";
import Constants from "../../services/constant";
import { showToast } from "./toastAction";
import { showLoaderAction, hideLoaderAction } from "./loaderAction";

const getPlans = (setLoading) => async (dispatch) => {
    if (setLoading) {
        setLoading(true);
    }
    const res = await api("get", Constants.END_POINT.PLANS);
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.CHANGE_PLANS,
                payload: res.data,
            });
        }
    }
    if (setLoading) {
        setLoading(false);
    }
};
const addPlans = (data, setLoading, history) => async (dispatch) => {
    setLoading(true);

    const res = await api("post", Constants.END_POINT.PLANS, data);
    if (res.success) {
        history.goBack();
        dispatch(getPlans(null));
        dispatch(showToast({ severity: "success", summary: res.message }));
    }
    setLoading(false);
};
const getPlan = (id, returnData) => async (dispatch) => {
    dispatch(showLoaderAction());
    const res = await api("get", Constants.END_POINT.PLANS + id);
    if (res.success) {
        dispatch(hideLoaderAction());
        if (res.data) {
            if (returnData) {
                returnData(res.data);
            }
        }
    }
    dispatch(hideLoaderAction());
};
const editPlans = (id, data, setLoading, history) => async (dispatch) => {
    setLoading(true);

    const res = await api("put", Constants.END_POINT.PLANS + id, { ...data });
    if (res.success) {
        getPlans(null);
        history.goBack();
        dispatch(showToast({ severity: "success", summary: res.message }));
    }
    setLoading(false);
};
const deletePlanAction = (id) => async (dispatch) => {
    const res = await api("delete", Constants.END_POINT.PLANS + id);
    if (res.success) {
        dispatch(getPlans(null));
        dispatch(showToast({ severity: "success", summary: res.message }));
    } else {
        dispatch(showToast({ severity: "error", summary: res.message }));
    }
};

const reorderPlans = (plans, setLoading, next) => async (dispatch) => {
    let payload = plans.map((item) => item._id);
    setLoading(true);
    const res = await api("post", Constants.END_POINT.PLANS + "reorder", payload);
    setLoading(false);
    if (res.success) {
        if (next) {
            next();
        }
    }
};

export { getPlans, addPlans, getPlan, editPlans, deletePlanAction, reorderPlans };
