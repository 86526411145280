import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { deleteTrackAction, getTracks, reorderTracks } from "../../redux/actions/tracksAction";
import { confirmDelete } from "../../utils/commonFunctions";

export default function Index() {
    const history = useHistory();
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getTracks(setLoading));
    }, [dispatch]);

    let { allTracks } = useSelector((state) => state.tracks);


    const [loading, setLoading] = useState(false);

    const [search, setSearch] = useState("");
    const getFilteredData = (data, search) => {
        if (search.length) {
            data = data.filter(
                (item) =>
                    item?.title?.toLowerCase()?.includes(search)
            );
        }
        return data;
    };

    allTracks = getFilteredData(allTracks, search);


    const onDelete = (trackId, position) => {
        confirmDelete(
            () => {
                dispatch(deleteTrackAction(trackId));
            },
            "Do you want to delete this Track?",
            position
        );
    };

    const [reorderd, setReorderd] = useState([]);

    useEffect(() => {
        setReorderd(allTracks);
    }, [allTracks]);

    const handleChnage = (e) => {
        setReorderd(e.value);
    };

    const onSave = () => {
        dispatch(
            reorderTracks(reorderd, setLoading, () => {
                dispatch(getTracks())
                history.push("/tracks/");
            })
        );
    };
    allTracks = getFilteredData(allTracks, search);

    return {
        allTracks,
        loading,
        history,
        search,
        setSearch,
        onDelete,
        handleChnage,
        reorderd,
        onSave,
    };
}
