import React from "react";
import TableCard from "../../shared/TableCard/TableCard";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import TableImage from "../../shared/TableCard/TableImage";

export default function TracksTable({ heading, allTracks, loading, history, search, setSearch, onDelete }) {
    const actions = (d) => {
        return (
            <div className="flex justify-content-around">
                <i className="pi pi-eye cursor-pointer" onClick={() => history.push(`/track/view/${d._id}`)}></i>
                <i className="pi pi-pencil cursor-pointer" onClick={() => history.push(`/track/edit/${d._id}`)}></i>
                <i className="pi pi-trash cursor-pointer" onClick={() => onDelete(d._id)}></i>
            </div>
        );
    };

    const image = (r) => {
        const image = r.image;
        return <TableImage image={image} />;
    };

    return (
        <TableCard
            title={heading}
            onSearch={setSearch}
            searchKeyword={search}
            buttonTitle="Add Track"
            linkTo={`/track/add/`}
            extraButtons={[{ buttonTitle: "Sort", linkTo: `/track/reorder/` }]}
        >
            <DataTable paginator value={allTracks} showGridlines rows={10} loading={loading} emptyMessage="No Track found.">
                <Column field="title" header="Title" style={{ width: "250px" }}></Column>
                <Column body={image} header="Image" style={{ width: "80px" }}></Column>
                <Column field="type" header="Type" style={{ width: "250px" }}></Column>
                <Column field="duration" header="Duration" style={{ width: "80px" }}></Column>
                <Column body={actions} style={{ width: "80px" }} header="Action"></Column>
            </DataTable>
        </TableCard>
    );
}
