import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { allValidations } from "../../../utils/formValidations";
import { showFormErrors } from "../../../utils/commonFunctions";
import { addPlans, editPlans, getPlan } from "../../../redux/actions/plansAction";

export default function ProgramContainer() {
    const dispatch = useDispatch();
    const history = useHistory();

    const { id } = useParams();

    useEffect(() => {
        if (id) {
            dispatch(
                getPlan(id, (data) => {
                    setData({
                        tagLine: data.tagLine,
                        name: data.name,
                        period: data.period,
                        currency: data.currency,
                        price: data.price,
                        description: data.description,
                        days: data.days,
                        interval: data.interval,
                        isTrial: data.trial ? true : false,
                        trial: data.trial,
                        offer: data.offer,
                        features: data.features,
                        iosPlanId: data.iosPlanId,
                        androidPlanId: data.androidPlanId,
                        razorpayPlanId: data.razorpayPlanId,
                    });
                })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, dispatch]);

    const [formType, setFormType] = useState("ADD");
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({
        tagLine: "",
        name: "",
        period: "",
        currency: "",
        price: 0,
        description: "",
        isTrial: null,
        days: 0,
        interval: 0,
        trial: null,
        offer: "",
        features: [],
        iosPlanId: "",
        androidPlanId: "",
        razorpayPlanId: "",
    });

    const handleChange = ({ name, value }) => {
        if (formType !== "VIEW") {
            const formErrors = allValidations(name, value, data);
            if (name === "isTrial" && !value) data.trial = null
            setData((prev) => ({ ...prev, [name]: value, formErrors }));
        }
    };

    const onSubmit = () => {
        if (showFormErrors(data, setData)) {
            if (formType === "ADD") {
                dispatch(addPlans(data, setLoading, history));
            } else if (formType === "EDIT") {
                dispatch(editPlans(id, data, setLoading, history));
            }
        }
    };

    return { data, handleChange, loading, onSubmit, setFormType };
}
