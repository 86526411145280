import React from "react";
import Index from "./index";
import PlansTable from "./PlansTable";

const Plans = () => {
    const { allPlans, loading, history, search, setSearch, onDelete } = Index();
    return (
        <PlansTable
            heading="All Plans"
            allPlans={allPlans}
            loading={loading}
            history={history}
            search={search}
            setSearch={setSearch}
            onDelete={onDelete}
        />
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname && prevProps.colorMode === nextProps.colorMode;
};
export default React.memo(Plans, comparisonFn);
