import api from "../../services/api";
import { types } from "../types/types";
import Constants from "../../services/constant";
import { showToast } from "./toastAction";
import { showLoaderAction, hideLoaderAction } from "./loaderAction";
import { uploadImages } from "../../utils/uploadFiles";

const getCategories = (setLoading) => async (dispatch) => {
  if (setLoading) {
    setLoading(true);
  }
  const res = await api("get", Constants.END_POINT.CATEGORY);
  if (res.success) {
    if (res.data) {
      dispatch({
        type: types.CHANGE_LOCATIONS_MENU,
        payload: res.data,
      });
    }
  }
  if (setLoading) {
    setLoading(false);
  }
};

const LocationMenuEnableDisableAction =
  (id, locationId, active) => async (dispatch) => {
    const payload = { isActive: active };
    const res = await api("put", Constants.END_POINT.MENU + id, payload);
    if (res.success) {
      dispatch(getCategories(null, locationId));
      dispatch(showToast({ severity: "success", summary: res.message }));
    }
  };
const addCategory = (data, setLoading, history) => async (dispatch) => {
  setLoading(true);

  if (data.image.length) {
    let urls = await uploadImages(data.image);
    data.image = urls[0];
  }
  if (data.coverImage.length) {
    let urls = await uploadImages(data.coverImage);
    data.coverImage = urls[0];
  }
  const payload = {
    ...data,
  };

  const res = await api("post", Constants.END_POINT.CATEGORY, payload);
  if (res.success) {
    history.goBack();
    dispatch(getCategories(null))
    dispatch(showToast({ severity: "success", summary: res.message }));
  }
  setLoading(false);
};
const editCategory =
  (id, data, setLoading, history) => async (dispatch) => {
    setLoading(true);

    if (data.image.length) {
      let urls = await uploadImages(data.image);
      data.image = urls[0];
    }
    if (data.coverImage.length) {
      let urls = await uploadImages(data.coverImage);
      data.coverImage = urls[0];
    }

    const payload = {
      ...data,
    };

    const res = await api("put", Constants.END_POINT.CATEGORY + id, payload);
    if (res.success) {
      getCategories(null);
      history.goBack();
      dispatch(showToast({ severity: "success", summary: res.message }));
    }
    setLoading(false);
  };

export const deleteCategoryAction = (id) => async (dispatch) => {
  const res = await api("delete", Constants.END_POINT.CATEGORY + id);
  if (res.success) {
    dispatch(getCategories(null));
    dispatch(showToast({ severity: "success", summary: res.message }));
  } else {
    dispatch(showToast({ severity: "error", summary: res.message }));
  }
};
const getCategory = (id, returnData) => async (dispatch) => {
  dispatch(showLoaderAction());
  const res = await api("get", Constants.END_POINT.CATEGORY + id);
  if (res.success) {
    dispatch(hideLoaderAction());
    if (res.data) {
      if (returnData) {
        returnData(res.data);
      }
    }
  }
  dispatch(hideLoaderAction());
};

const reorderMenu = (locations, setLoading, next) => async (dispatch) => {
  let payload = locations.map((item) => item._id);
  setLoading(true);
  const res = await api("post", Constants.END_POINT.CATEGORY + "reorder", payload);
  setLoading(false);
  if (res.success) {
    if (next) {
      next();
    }
  }
};

export {
  getCategories,
  LocationMenuEnableDisableAction,
  addCategory,
  getCategory,
  editCategory,
  reorderMenu,
};
