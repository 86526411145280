import React from "react";
import CustomCard from "../../../shared/Card/CustomCard";
import TrackForm from "./TrackForm";

export default function AddTrack({ name ,backable,setOpenTasks}) {
  return (
    <CustomCard title={name} backable={backable}>
      <TrackForm type="ADD" setOpenTasks={setOpenTasks} />
    </CustomCard>
  );
}
