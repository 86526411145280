import React from "react";
import TableCard from "../../shared/TableCard/TableCard";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

export default function PlansTable({ heading, allPlans, loading, history, search, setSearch, onDelete }) {
    const actions = (d) => {
        return (
            <div className="flex justify-content-around">
                <i className="pi pi-eye cursor-pointer" onClick={() => history.push(`/plan/view/${d._id}`)}></i>
                <i className="pi pi-pencil cursor-pointer" onClick={() => history.push(`/plan/edit/${d._id}`)}></i>
                <i className="pi pi-trash cursor-pointer" onClick={() => onDelete(d._id)}></i>
            </div>
        );
    };

    return (
        <TableCard
            title={heading}
            onSearch={setSearch}
            searchKeyword={search}
            buttonTitle="Add Plan"
            linkTo={`/plan/add/`}
            extraButtons={[{ buttonTitle: "Sort", linkTo: `/plan/reorder/` }]}
        >
            <DataTable paginator value={allPlans} showGridlines rows={10} loading={loading} emptyMessage="No Plan found.">
                <Column field="name" header="Name" style={{ width: "250px" }}></Column>
                <Column field="description" header="Description" style={{ width: "250px" }}></Column>
                <Column field="price" body={(r) => `₹${r.price}`} header="Price" style={{ width: "250px" }}></Column>
                <Column field="days" header="Days" style={{ width: "250px" }}></Column>
                <Column body={actions} style={{ width: "80px" }} header="Action"></Column>
            </DataTable>
        </TableCard>
    );
}
