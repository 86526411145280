import React, { useState, useEffect } from "react";
import { allValidations } from "../../utils/formValidations";
import { showFormErrors } from "../../utils/commonFunctions";
import { useDispatch } from "react-redux";
import { CustomForm, CustomInput } from "../../shared/Input/AllInputs";
import PrimaryButton from "../../shared/Button/PrimaryButton";
import { ChangeAppDescAction, getAppDescAction } from "../../redux/actions/dashboardAction";

const ChangeAppDesc = () => {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(
            getAppDescAction((data) => {
                setData({
                    primaryTitle: data.primaryTitle,
                    primaryDescription: data.primaryDescription,
                    interval: data.interval,
                    title: data.title,
                    description: data.description,
                });
            })
        );
    }, [dispatch]);

    const [data, setData] = useState({
        primaryTitle: "",
        primaryDescription: "",
        interval: "",
        title: "",
        description: "",
    });

    const handleChange = ({ name, value }) => {
        const formErrors = allValidations(name, value, data);
        setData((prev) => ({ ...prev, [name]: value, formErrors }));
    };

    const onSubmit = () => {
        if (showFormErrors(data, setData)) {
            dispatch(ChangeAppDescAction(data, setLoading, setData));
        }
    };
    return (
        <>
            <div className="col-12 xl:col-6">
                <div className="card">
                    <h5>App Dashboard Message</h5>
                    <>
                        <CustomForm>
                            <CustomInput label="Title" name="title" required data={data} col={12} onChange={handleChange} />
                            <CustomInput label="Description" name="description" required data={data} col={12} onChange={handleChange} />
                            <CustomInput label="After days" name="interval" required data={data} col={12} onChange={handleChange} />
                            <CustomInput label="Primary Title" name="primaryTitle" required data={data} col={12} onChange={handleChange} />
                            <CustomInput
                                label="Primary Description"
                                name="primaryDescription"
                                required
                                data={data}
                                col={12}
                                onChange={handleChange}
                            />
                            <PrimaryButton loading={loading} label="Save" onClick={onSubmit} col={6} />
                        </CustomForm>
                    </>
                </div>
            </div>
        </>
    );
};

export default ChangeAppDesc;
