import React, { useState } from "react";
import { allValidations } from "../../utils/formValidations";
import { showFormErrors } from "../../utils/commonFunctions";
import { useDispatch } from "react-redux";
import { CustomForm, CustomInput } from "../../shared/Input/AllInputs";
import PrimaryButton from "../../shared/Button/PrimaryButton";
import { sendNotification } from "../../redux/actions/dashboardAction";

const SendNotification = ({ selectedRow, setSelectedRow }) => {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const [data, setData] = useState({
        title: "",
        description: "",
    });

    const handleChange = ({ name, value }) => {
        const formErrors = allValidations(name, value, data);
        setData((prev) => ({ ...prev, [name]: value, formErrors }));
    };

    const onSubmit = () => {
        if (showFormErrors(data, setData)) {
            dispatch(sendNotification(data, setLoading, setData, selectedRow, setSelectedRow));
        }
    };
    return (
        <>
            <div className="col-12 xl:col-12">
                <div className="card">
                    <h5> Send Notifications</h5>
                    <>
                        <CustomForm>
                            <CustomInput label="Title" name="title" required data={data} onChange={handleChange} />
                            <CustomInput label="Description" name="description" required data={data} onChange={handleChange} />
                            <PrimaryButton loading={loading} label="Send" onClick={onSubmit} col={6} />
                        </CustomForm>
                    </>
                </div>
            </div>
        </>
    );
};

export default SendNotification;
