import React, { useEffect } from "react";
import { CustomForm, CustomInput, CustomTextArea } from "../../../shared/Input/AllInputs";
import PrimaryButton from "../../../shared/Button/PrimaryButton";
import AuthorContainer from "./AuthorContainer";
import CustomImageInput from "../../../shared/Input/CustomImageInput";

export default function AuthorForm({ type }) {
    const { data, handleChange, loading, onSubmit, setFormType } = AuthorContainer();
    useEffect(() => {
        setFormType(type);
    }, [type, setFormType]);

    return (
        <>
            <CustomForm style={type === "VIEW" ? { pointerEvents: "none" } : {}}>
                <CustomImageInput
                    name="image"
                    label="Upload Image"
                    onFilesChange={handleChange}
                    data={data}
                    editable={type !== "VIEW" ? true : false}
                />
                <CustomInput label="Name" name="name" required data={data} onChange={handleChange} />
                <CustomInput label="Role" name="role" required data={data} onChange={handleChange} />

                <CustomTextArea label="Short Description" name="shortDescription" required data={data} onChange={handleChange} />
                <CustomTextArea label="Description" name="description" required data={data} onChange={handleChange} />
                {type !== "VIEW" && <PrimaryButton label="Save" loading={loading} onClick={onSubmit} col={6} />}
            </CustomForm>
        </>
    );
}
