import React from "react";
import CustomCard from "../../../shared/Card/CustomCard";
import AuthorForm from "./AuthorForm";

export default function ViewAuthor({ name }) {
    return (
        <CustomCard title={name}>
            <AuthorForm type="VIEW" />
        </CustomCard>
    );
}
