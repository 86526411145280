import React from "react";
import CustomCard from "../../shared/Card/CustomCard";
import CustomerForm from "./CustomerForm";

export default function ViewCustomer({ name }) {
    return (
        <CustomCard title={name}>
            <CustomerForm type="VIEW" />
        </CustomCard>
    );
}
