import React from "react";
import CustomerTable from "./CustomerTable";
import Index from "./index";

const Customers = () => {
    const { allCustomers, loading, history, search, setSearch, onDelete, headerContent } = Index();
    return (
        <CustomerTable
            heading="All Customers"
            allCustomers={allCustomers}
            loading={loading}
            history={history}
            search={search}
            setSearch={setSearch}
            onDelete={onDelete}
            headerContent={headerContent}
        />
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname && prevProps.colorMode === nextProps.colorMode;
};
export default React.memo(Customers, comparisonFn);
