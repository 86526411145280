import React from "react";
import Index from "./index";
import TracksTable from "./TracksTable";

const Tracks = () => {
  const { allTracks,
    loading,
    history,
    search,
    setSearch,
    onDelete,
 } = Index();
  return (
    <TracksTable
      heading="All Tracks"
      allTracks={allTracks}
      loading={loading}
      history={history}
      search={search}
      setSearch={setSearch}
      onDelete={onDelete}
    />
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return (
    prevProps.location.pathname === nextProps.location.pathname &&
    prevProps.colorMode === nextProps.colorMode
  );
};
export default React.memo(Tracks, comparisonFn);
