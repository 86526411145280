// const BASE_URL = "https://namaapi.appdeft.biz/api/admin";
// const HOST = "https://namaapi.appdeft.biz/";

const BASE_URL = "https://api.namameditation.com/api/admin";
const HOST = "https://api.namameditation.com/";

// const BASE_URL = "http://localhost:1010/api/admin";
// const HOST = "http://localhost:1010/";

export { BASE_URL, HOST };
