export default class Constants {
    static END_POINT = {
        LOGIN: "/login",
        PROFILE: "/profile",
        FORGET_PASSWORD: "/forget-password",
        UPDATE_PROFILE: "/update",
        RESET_PASSWORD: "/change-forget-password",
        UPLOAD_FILE: "/upload-files",
        CHANGE_PASSWORD: "/change-password",
        //Dashborad
        GET_USERS_COUNT: "/user/count",
        NOTIFICATION: "/notification",
        CHANGE_APP_DESC: "/constant",
        //User tabs
        CUSTOMER: "/user/",
        ENABLE_DISABLE_CUSTOMER: "/block-unblock-user/",
        CATEGORY: "/category/",
        TRACKS: "/track/",
        PROGRAMS: "/program/",
        PLANS: "/plan/",
        AUTHORS: "/author/",
        FEEDBACKS: "/user-account-delete-reasons/",
    };
}
