import React, { useEffect } from "react";
import { CustomCalenderInput, CustomDropDown, CustomForm, CustomInput } from "../../shared/Input/AllInputs";
import { CustomPhoneNumber } from "../../shared/Input/CustomPhoneInput";
import { GenderOptions } from "../../utils/constant";
import CustomImageInput from "../../shared/Input/CustomImageInput";
import CustomerContainer from "./CustomerContainer";
import PrimaryButton from "../../shared/Button/PrimaryButton";

const CustomerForm = ({ type }) => {
    const { data, handleChange, loading, onSubmit, setFormType } = CustomerContainer();
    useEffect(() => {
        setFormType(type);
    }, [type, setFormType]);

    return (
        <CustomForm style={type === "VIEW" ? { pointerEvents: "none" } : {}}>
            <CustomImageInput name="image" label="User Image" data={data} editable={false} />
            <CustomInput label="First Name" name="firstName" data={data} onChange={handleChange} />
            <CustomInput label="Last Name" name="lastName" data={data} onChange={handleChange} />
            <CustomInput label="Email Address" name="email" data={data} disabled />
            <CustomPhoneNumber label="Mobile Number" value={data?.countryCode + data?.mobile} disabled />
            <CustomInput label="City" name="city" data={data} onChange={handleChange} />
            <CustomCalenderInput label="Date Of Birth" name="dateOfBirth" data={data} onChange={handleChange} />
            <CustomDropDown name="gender" label="Gender" options={GenderOptions} data={data} onChange={handleChange} />
            <CustomCalenderInput label="Subscription Expires" name="subscriptionExpireOn" data={data} onChange={handleChange} dateFormat="dd/mm/yy" />
            {type !== "VIEW" && <PrimaryButton label="Save Customer" loading={loading} onClick={onSubmit} col={6} />}
        </CustomForm>
    );
};

export default CustomerForm;
