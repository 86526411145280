import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { allValidations } from "../../../utils/formValidations";
import { showFormErrors } from "../../../utils/commonFunctions";
import { addAuthor, editAuthor, getAuthor } from "../../../redux/actions/authorAction";

export default function AuthorContainer() {
    const dispatch = useDispatch();
    const history = useHistory();

    const { id } = useParams();

    useEffect(() => {
        if (id) {
            dispatch(
                getAuthor(id, (data) => {
                    setData({
                        name: data.name,
                        image: data.image ? [data.image] : [],
                        shortDescription: data.shortDescription,
                        description: data.description,
                        role: data.role,
                    });
                })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, dispatch]);

    const [formType, setFormType] = useState("ADD");
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({
        name: "",
        shortDescription: "",
        description: "",
        image: [],
        role: "",
    });

    const handleChange = ({ name, value }) => {
        if (formType !== "VIEW") {
            const formErrors = allValidations(name, value, data);
            setData((prev) => ({ ...prev, [name]: value, formErrors }));
        }
    };

    console.log(data);

    const onSubmit = () => {
        if (showFormErrors(data, setData)) {
            if (formType === "ADD") {
                dispatch(addAuthor(data, setLoading, history));
            } else if (formType === "EDIT") {
                dispatch(editAuthor(id, data, setLoading, history));
            }
        }
    };

    return { data, handleChange, loading, onSubmit, setFormType };
}
