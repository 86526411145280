import React from "react";
import CustomCard from "../../../shared/Card/CustomCard";
import ProgramForm from "./ProgramForm";

export default function AddProgram({ name }) {
    return (
        <CustomCard title={name}>
            <ProgramForm type="ADD" />
        </CustomCard>
    );
}
