export const types = {
    SHOW_TOAST: "SHOW_TOAST",
    SHOW_LOADER: "SHOW_LOADER",
    HIDE_LOADER: "HIDE_LOADER",
    USER_DETAIL: "USER_DETAIL",
    CHANGE_CUSTOMERS: "CHANGE_CUSTOMERS",
    CHANGE_EMPLOYEES: "CHANGE_EMPLOYEES",
    CHANGE_LOCATIONS_MENU: "CHANGE_LOCATIONS_MENU",
    CHANGE_TRACKS: "CHANGE_TRACKS",
    CHANGE_PROGRAMS: "CHANGE_PROGRAMS",
    UPDATE_UPLOAD_PROGRESS: "UPDATE_UPLOAD_PROGRESS",
    CHANGE_PLANS: "CHANGE_PLANS",
    CHANGE_FEEDBACKS: "CHANGE_FEEDBACKS",
    CHANGE_AUTHORS: "CHANGE_AUTHORS",
};
