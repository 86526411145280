import React, { useEffect } from "react";
import {
    CustomChipInput,
    CustomDropDown,
    CustomForm,
    CustomInput,
    CustomInputSwitch,
    CustomMultiSelect,
    CustomTextArea,
} from "../../../shared/Input/AllInputs";
import PrimaryButton from "../../../shared/Button/PrimaryButton";
import CustomImageInput from "../../../shared/Input/CustomImageInput";
import TrackContainer from "./TrackContainer";
import CustomAudioInput from "../../../shared/Input/CustomAudioInput";
import { LevelOptions } from "../../../utils/constant";

export default function TrackForm({ type, setOpenTasks }) {
    const { data, handleChange, loading, onSubmit, setFormType, allLocationsMenu, allAuthors } = TrackContainer(setOpenTasks);
    useEffect(() => {
        setFormType(type);
    }, [type, setFormType]);

    return (
        <>
            <CustomForm style={type === "VIEW" ? { pointerEvents: "none" } : {}}>
                <CustomImageInput
                    name="image"
                    label="Upload Image"
                    col={6}
                    onFilesChange={handleChange}
                    data={data}
                    editable={type !== "VIEW" ? true : false}
                />
                <CustomImageInput
                    name="banner"
                    label="Instagram Banner"
                    col={6}
                    onFilesChange={handleChange}
                    data={data}
                    editable={type !== "VIEW" ? true : false}
                />
                <CustomAudioInput
                    name="music"
                    label="Upload New Audio"
                    col={12}
                    onFilesChange={handleChange}
                    data={data}
                    editable={type !== "VIEW" ? true : false}
                />
                <CustomInput label="Title" name="title" required data={data} onChange={handleChange} />
                <CustomMultiSelect
                    data={data}
                    onChange={handleChange}
                    name="category"
                    label="Category"
                    options={allLocationsMenu?.map((item, index) => {
                        return { name: item?.name, value: item?._id };
                    })}
                    col={6}
                />

                <CustomDropDown data={data} onChange={handleChange} name="level" label="Level" options={LevelOptions} />

                {/* <CustomDropDown data={data} onChange={handleChange} name="type" label="Type" options={MeditationType} /> */}
                <CustomInput label="Type" name="type" required data={data} onChange={handleChange} />

                <CustomChipInput data={data} label="What you expect" name="whatYouExpect" onChange={handleChange} />
                <CustomChipInput data={data} label="How to meditate" name="howToMeditate" onChange={handleChange} />
                <CustomTextArea label="Description" name="description" required data={data} onChange={handleChange} />
                <CustomDropDown
                    data={data}
                    onChange={handleChange}
                    name="author"
                    label="Author"
                    options={allAuthors?.map((item) => {
                        return { name: item?.name, value: item?._id };
                    })}
                />
                <CustomInput label="Bonus Tip" name="bonusTip" data={data} onChange={handleChange} />

                <CustomInputSwitch label="Recommended" name="isRecommended" data={data} col={2} onChange={handleChange} />
                <CustomInputSwitch label="Subscription Required" name="isSubscriptionRequired" data={data} col={3} onChange={handleChange} />

                {type !== "VIEW" && <PrimaryButton label="Save Tracks" loading={loading} onClick={onSubmit} col={6} />}
            </CustomForm>
        </>
    );
}
