import React from "react";
import CustomCard from "../../shared/Card/CustomCard";
import CustomerForm from "./CustomerForm";

export default function EditCustomer({ name }) {
    return (
        <CustomCard title={name}>
            <CustomerForm type="EDIT" />
        </CustomCard>
    );
}
