const menuItems = () => {
    return [
        {
            items: [
                {
                    icon: "pi pi-home",
                    label: "Home",
                    to: "/dashboard",
                },
                {
                    icon: "pi pi-user ",
                    label: "Customers",
                    to: "/customers",
                },

                {
                    icon: "pi pi-th-large",
                    label: "Categories",
                    to: "/categories",
                },
                {
                    icon: "pi pi-users",
                    label: "Authors",
                    to: "/authors",
                },
                {
                    icon: "pi pi-play",
                    label: "Tracks",
                    to: "/tracks",
                },
                {
                    icon: "pi pi-chart-bar",
                    label: "Programs",
                    to: "/programs",
                },
                {
                    icon: "pi pi-id-card",
                    label: "Plans",
                    to: "/plans",
                },
                {
                    icon: "pi pi-comment",
                    label: "Feedbacks",
                    to: "/feedbacks",
                },
                {
                    icon: "pi pi-cog",
                    label: "Settings",
                    to: "/settings",
                },
            ],
        },
    ];
};

export { menuItems };
