import api from "../../services/api";
import { types } from "../types/types";
import Constants from "../../services/constant";
import { showToast } from "./toastAction";
import { showLoaderAction, hideLoaderAction } from "./loaderAction";
import { uploadImages } from "../../utils/uploadFiles";

const getTracks = (setLoading) => async (dispatch) => {
    if (setLoading) {
        setLoading(true);
    }
    const res = await api("get", Constants.END_POINT.TRACKS);
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.CHANGE_TRACKS,
                payload: res.data,
            });
        }
    }
    if (setLoading) {
        setLoading(false);
    }
};
const addTracks = (data, duration, setLoading, history, setOpenTasks, dispatch1) => async (dispatch) => {
    setLoading(true);

    if (data?.image?.length) {
        let urls = await uploadImages(data.image, dispatch1);
        data.image = urls[0];
    }
    if (data?.banner?.length) {
        let urls = await uploadImages(data.banner, dispatch1);
        data.banner = urls[0];
    }
    if (data?.music?.length) {
        let urls = await uploadImages(data.music, dispatch1);
        data.music = urls[0];
    }
    const payload = {
        ...data,
        duration: duration,
    };

    const res = await api("post", Constants.END_POINT.TRACKS, payload);
    if (res.success) {
        if (setOpenTasks) {
            setOpenTasks(false);
        } else {
            history.goBack();
        }
        dispatch(getTracks(null));
        dispatch(showToast({ severity: "success", summary: res.message }));
    }
    setLoading(false);
};
const editTracks = (id, duration, data, setLoading, history, dispatch1) => async (dispatch) => {
    setLoading(true);

    if (data?.image?.length) {
        let urls = await uploadImages(data.image, dispatch1);
        data.image = urls[0];
    }
    if (data?.banner?.length) {
        let urls = await uploadImages(data.banner, dispatch1);
        data.banner = urls[0];
    }
    if (data?.music?.length) {
        let urls = await uploadImages(data.music, dispatch1);
        data.music = urls[0];
    }

    const payload = {
        ...data,
        duration: duration,
    };

    const res = await api("put", Constants.END_POINT.TRACKS + id, payload);
    if (res.success) {
        getTracks(null);
        history.goBack();
        dispatch(showToast({ severity: "success", summary: res.message }));
    }
    setLoading(false);
};

const getTrack = (id, returnData) => async (dispatch) => {
    dispatch(showLoaderAction());
    const res = await api("get", Constants.END_POINT.TRACKS + id);
    if (res.success) {
        dispatch(hideLoaderAction());
        if (res.data) {
            if (returnData) {
                returnData(res.data);
            }
        }
    }
    dispatch(hideLoaderAction());
};
const deleteTrackAction = (id) => async (dispatch) => {
    const res = await api("delete", Constants.END_POINT.TRACKS + id);
    if (res.success) {
        dispatch(getTracks(null));
        dispatch(showToast({ severity: "success", summary: res.message }));
    } else {
        dispatch(showToast({ severity: "error", summary: res.message }));
    }
};
const reorderTracks = (tracks, setLoading, next) => async (dispatch) => {
    let payload = tracks.map((item) => item._id);
    setLoading(true);
    const res = await api("post", Constants.END_POINT.TRACKS + "reorder", payload);
    setLoading(false);
    if (res.success) {
        if (next) {
            next();
        }
    }
};

export { getTracks, addTracks, getTrack, editTracks, deleteTrackAction, reorderTracks };
