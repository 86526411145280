import { combineReducers } from "redux";
import loaderReducer from "./loaderReducer";
import profileReducer from "./profileReducer";
import toastReducer from "./toastReducer";
import dashboardReducer from "./dashboardReducer";
import customersReducer from "./customerReducer";
import employeeReducer from "./employeeReducer";
import locationMenuReducer from "./locationMenuReducer";
import tracksReducer from "./tracksReducer";
import programReducer from "./programReducer";
import uploadReducer from "./uploadReducer";
import plansReducer from "./plansReducer";
import authorReducer from "./authorReducer";

export default combineReducers({
    toast: toastReducer,
    loader: loaderReducer,
    profile: profileReducer,
    dashboard: dashboardReducer,
    customer: customersReducer,
    employee: employeeReducer,
    locationMenu: locationMenuReducer,
    tracks: tracksReducer,
    programs: programReducer,
    upload: uploadReducer,
    plans: plansReducer,
    author: authorReducer,
});
