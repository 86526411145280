import React from "react";
import CustomCard from "../../../shared/Card/CustomCard";
import PlanForm from "./PlanForm";

export default function ViewPlan({ name }) {
    return (
        <CustomCard title={name}>
            <PlanForm type="VIEW" />
        </CustomCard>
    );
}
